import classNames from 'classnames'

export default function ColSort({
  label,
  onClick,
  active,
  direction,
  classOff = 'av-icon-sort',
  classDesc = 'bi-sort-down',
  classAsc = 'bi-sort-up',
}) {
  return (
    <div className="d-flex aling-items-center">
      <div>{label}</div>
      <div>
        <button onClick={onClick} className="av-btn-icon-circle ms-2">
          <i
            className={classNames('bi', {
              [classOff]: !active,
              [classDesc]: active && direction === 'desc',
              [classAsc]: active && direction === 'asc',
            })}
          />
        </button>
      </div>
    </div>
  )
}
