import { createContext, useContext } from 'react'
import { rj, useRj } from 'react-rocketjump'
import api from '../api'

const DeliveryRetryState = rj().effect({
  effectCaller: 'configured',
  effect: (t) => (id) => api.auth(t).post(`/api/or/dash/video-delivery-configurations/${id}/retry`),
})

export function useDeliveryRetry() {
  return useRj(DeliveryRetryState, [])
}
