import { useAuthState } from 'use-eazy-auth'
import { ConfigContext, useFetchConfig } from '../hooks/config'

function AuthConfig({ children, fallback = null }) {
  const config = useFetchConfig()
  if (config) {
    return (
      <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
    )
  }
  return fallback
}

export default function Config({ children, fallback }) {
  const { authenticated, bootstrappedAuth } = useAuthState()
  if (!bootstrappedAuth) {
    return fallback
  }
  if (authenticated) {
    return <AuthConfig fallback={fallback}>{children}</AuthConfig>
  }
  return children
}
