import { useMemo } from 'react'
import DeliveryChannelRow from '../components/DeliveryChannelRow'
import { useDeliveryChannelsList } from '../hooks/deliveryChannels'

export default function DeliveryChannels() {
  const [{ list: deliveryChannels }] = useDeliveryChannelsList()

  return (
    <div className="container-fluid av-content">
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Label</th>
              <th>Type</th>
              <th>Last Update</th>
              <th>Estimated expiry</th>
              <th>Timeline</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {deliveryChannels &&
              deliveryChannels.map((deliveryChannel) => (
                <DeliveryChannelRow deliveryChannel={deliveryChannel} key={deliveryChannel.id} />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
