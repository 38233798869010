import dayjs from 'dayjs'
import cronstrue from 'cronstrue'
import { memo } from 'react'
import { useNextCronDate } from '../hooks/cron'
import { useAuthUser } from 'use-eazy-auth'
import DisplayJsonData from './DisplayJsonData'
import { useConfig } from '../hooks/config'

function CronJobRow({ cronJob, onCheckChange }) {
  const expression = cronJob.cron_expression
  const nextDate = useNextCronDate(expression)
  let when
  try {
    when = cronstrue.toString(expression, {
      use24HourTimeFormat: true,
      verbose: true,
    })
  } catch (_) {
    when = null
  }

  const { user } = useAuthUser()
  const config = useConfig()

  return (
    <tr>
      {user.is_superuser && <td>{cronJob.id}</td>}
      <td>{config.templates[cronJob.job_type] ?? cronJob.job_type}</td>
      {user.is_superuser && (
        <td style={{maxWidth:200}}>
          <DisplayJsonData data={cronJob.kwargs} />
        </td>
      )}
      <td>{cronJob.location}</td>
      <td>
        <small>{when}</small>
      </td>
      <td>{dayjs(nextDate).format('DD/MM/YYYY HH:mm')}</td>
      {user.is_superuser && <td>{expression}</td>}
      <td>{cronJob.kwargs.subtitles ?? true ? 'Yes' : 'No'}</td>
      {user.is_superuser && (
        <td>
          <div className="d-flex aling-items-center justify-content-center">
            <input
              checked={cronJob.enabled}
              onChange={() => onCheckChange(cronJob)}
              type="checkbox"
              className="av-checkbox"
            />
          </div>
        </td>
      )}
    </tr>
  )
}

export default memo(CronJobRow)
