import { rj, useRunRj } from 'react-rocketjump'
import api from '../api'

const MachinesListState = rj().effect({
  effectCaller: 'configured',
  effect:
    (t) =>
    (params = {}) =>
      api.auth(t).get(`/api/or/dash/machines`, params),
})

export function useMachinesList(params) {
  return useRunRj(MachinesListState, [params], false)
}
