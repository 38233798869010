import { useCallback, useMemo, useState } from 'react'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'
import { useAuthUser } from 'use-eazy-auth'
import ColMultiSelector from '../components/ColMuliSelector/ColMuliSelector'
import CronJobRow from '../components/CronJobRow'
import { useCronJobsList, useNextCronDate } from '../hooks/cron'
import { getNextCronDate } from '../utils'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import dayjs from 'dayjs'
import ColSort from '../components/ColSort'
import { ColAlphaSort } from '../components/ColAlphaSort'
import { useConfig } from '../hooks/config'

function ConfirmExportContent({ job }) {
  const nextDate = useNextCronDate(job.cron_expression)
  const hasSubitles = job.kwargs.subtitles ?? true
  return (
    <div>
      By adding this function, the video export{' '}
      <b>
        {hasSubitles ? 'with' : 'without'} subtitles will{' '}
        {job.enabled ? 'not ' : ''} be scheduled
      </b>{' '}
      starting from {dayjs(nextDate).format('DD/MM/YYYY HH:mm')}.
    </div>
  )
}

export default function Schedules() {
  const config = useConfig()
  const [{ data: allCronJobs }, { patchJob }] = useCronJobsList()
  const { user } = useAuthUser()

  const [templates, setTemplates] = useState(() => null)
  const templateOptions = useMemo(
    () =>
      uniqBy(allCronJobs ?? [], 'job_type')
        .map((o) => o.job_type)
        .sort()
        .map((value) => ({
          value,
          label: config.templates[value] ?? value,
        })),
    [allCronJobs, config]
  )
  const selectedTemplates = useMemo(
    () => templates ?? templateOptions.map((o) => o.value),
    [templateOptions, templates]
  )

  const [sort, setSort] = useState([])
  const [sortField, sortDirection] = sort

  const updateSort = useCallback((field) => {
    setSort((prevSort) => {
      const [prevField, prevDirection] = prevSort
      // New sort
      if (field !== prevField) {
        return [field, 'desc']
      }
      // Other direction
      if (prevDirection === 'desc') {
        return [field, 'asc']
      }
      // No sort
      return []
    })
  }, [])

  const cronJobs = useMemo(() => {
    const filteredJobs = (allCronJobs ?? [])
      // Normalize fields for better filter / sorting
      .map((job) => {
        return {
          ...job,
          nextPub: getNextCronDate(job.cron_expression),
        }
      })
      // Filter from state
      .filter((j) => {
        // Show all templates
        if (templates === null) {
          return true
        }
        return templates.includes(j.job_type)
      })
    // Finally when needed apply sort
    if (sort[0]) {
      return orderBy(filteredJobs, [sort[0]], [sort[1]])
    }
    return filteredJobs
  }, [allCronJobs, sort, templates])

  const [modalEnabled, modalEnabledActions] = useModalTrigger()

  const handleJobCheckChange = useCallback(
    (job) => {
      modalEnabledActions.open(job)
    },
    [modalEnabledActions]
  )

  return (
    <div className="container-fluid av-content">
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              {user.is_superuser && <th>#</th>}
              <th className="col-more-zindex">
                <ColMultiSelector
                  isAllSelected={
                    selectedTemplates.length === templateOptions.length
                  }
                  allLabel={'All Templates'}
                  label={'Template'}
                  value={selectedTemplates}
                  onChange={setTemplates}
                  options={templateOptions}
                />
              </th>
              {user.is_superuser && <th style={{maxWidth:200}}>Data</th>}
              <th>
                <ColAlphaSort
                  label={'Location'}
                  direction={sortDirection}
                  active={sortField === 'location'}
                  onClick={() => updateSort('location')}
                />
              </th>
              <th>Publish frequency</th>
              <th>
                <ColSort
                  label={'Next publish'}
                  direction={sortDirection}
                  active={sortField === 'nextPub'}
                  onClick={() => updateSort('nextPub')}
                />
              </th>
              {user.is_superuser && <th>Expression</th>}
              <th>Subtitle</th>
              {user.is_superuser && <th>Export video</th>}
            </tr>
          </thead>
          <tbody>
            {cronJobs &&
              cronJobs.map((cronJob) => (
                <CronJobRow
                  onCheckChange={handleJobCheckChange}
                  cronJob={cronJob}
                  key={cronJob.id}
                />
              ))}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={modalEnabled.isOpen}
        toggle={modalEnabledActions.toggle}
        onClosed={modalEnabledActions.onClosed}
      >
        <ModalHeader toggle={modalEnabledActions.toggle}>
          Are you sure?
        </ModalHeader>
        <ModalBody>
          {modalEnabled.value && (
            <ConfirmExportContent job={modalEnabled.value} />
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={() => {
              modalEnabledActions.close()
            }}
            className="btn av-btn-secondary-flat"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              const pJob = modalEnabled.value
              patchJob
                .onSuccess(() => {
                  modalEnabledActions.close()
                })
                .run({
                  id: pJob.id,
                  enabled: !pJob.enabled,
                })
            }}
            className="btn av-btn-primary-flat"
          >
            Confirm
          </button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
