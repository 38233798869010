import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  UncontrolledDropdown,
} from 'reactstrap'
import { useAuthActions, useAuthUser } from 'use-eazy-auth'

export default function TopBar() {
  const { user } = useAuthUser()
  const { logout } = useAuthActions()

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <Navbar className="av-navbar" color="dark" expand="md" dark>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="me-auto" navbar>
          <NavItem>
            <NavLink className="nav-link" to="/video">
              Video
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="nav-link" to="/schedules">
              Schedules
            </NavLink>
          </NavItem>
          {/* {user.delivery_management && (
            <NavItem>
              <NavLink className="nav-link" to="/delivery">
                Delivery management old
              </NavLink>
            </NavItem>
          )} */}
          {user.delivery_management && (
            <NavItem>
              <NavLink className="nav-link" to="/delivery-channels">
                Delivery management
              </NavLink>
            </NavItem>
          )}
          {user.is_superuser && (
            <NavItem>
              <NavLink className="nav-link" to="/machines">
                Machines
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <Nav navbar>
          <UncontrolledDropdown nav inNavbar={isOpen}>
            <DropdownToggle caret nav>
              {user.is_superuser && (
                <i style={{ color: 'gold' }} className="bi bi-star-fill me-2" />
              )}
              {user.name
                .split(' ')
                .map((x) => x[0] ?? '')
                .join('')
                .toUpperCase()
                .replace(/[^A-Z]/g, '')}
            </DropdownToggle>
            <DropdownMenu>
              {/* <DropdownItem tag={Link} to="/profile">
                Profile
              </DropdownItem>
              <DropdownItem divider /> */}
              <DropdownItem onClick={() => logout()}>Log out</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  )
}
