import classNames from 'classnames'
import { useState } from 'react'
import './ColMultiSelector.css'

export default function ColMultiSelector({
  label,
  value,
  options,
  allLabel,
  onChange,
  isAllSelected,
}) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="d-flex align-items-center ColMultiSelectorContainer">
      <div>{label}</div>
      <button
        title="Filter"
        className="av-btn-icon-circle ms-2 position-relative"
        onClick={() => setIsOpen(!isOpen)}
      >
        <i className="bi bi-filter" />
        {!isAllSelected && <span className="active-multi-filter-circle" />}
      </button>
      <div
        style={{ maxHeight: isOpen ? 40 + options.length * 30 : 0 }}
        className={classNames('ColMultiSelector', {
          close: !isOpen,
        })}
      >
        <div className="ColMultiSelectorInner">
          <div className="ColMultiSelectorAllRow">
            <label className="me-2" style={{ whiteSpace: 'nowrap' }}>
              {allLabel}
            </label>
            <input
              type="checkbox"
              checked={isAllSelected}
              onChange={() => {
                if (isAllSelected) {
                  onChange([])
                } else {
                  onChange(options.map((o) => o.value))
                }
              }}
            />
          </div>
          <div className="ColMultiSelectorDivider" />
          {options.map((o) => {
            const checked = value.includes(o.value)
            return (
              <div className="ColMultiSelectorRow" key={o.value}>
                <label className="me-2">{o.label}</label>
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() => {
                    if (checked) {
                      onChange(value.filter((v) => v !== o.value))
                    } else {
                      onChange(value.concat(o.value))
                    }
                  }}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
