import dayjs from 'dayjs'
import { memo } from 'react'
import padStart from 'lodash/padStart'
import { useAuthUser } from 'use-eazy-auth'
import DisplayJsonData from './DisplayJsonData'
import { useConfig } from '../hooks/config'

function VideoState({ state, progress, cronStatus }) {
  if (cronStatus !== 'done') {
    if (cronStatus === 'running') {
      return (
        <div className="badge rounded-pill bg-blue-gradient">
          <i className="bi bi-hourglass-split me-2"></i>
          Cron Running
        </div>
      )
    }
    if (cronStatus === 'queued') {
      return (
        <div className="badge rounded-pill bg-blue-gradient">
          <i className="bi bi-hourglass-split me-2"></i>
          Cron Queued
        </div>
      )
    }
    if (cronStatus === 'error') {
      return (
        <div className="badge rounded-pill bg-orange-gradient">
          <i className="bi bi-hourglass me-2"></i>
          Cron Error
        </div>
      )
    }
    return null
  }
  if (!state) {
    return null
  }
  if (state === 'queued') {
    return (
      <div className="badge rounded-pill bg-blue-gradient">
        <i className="bi bi-hourglass me-2"></i>
        Queue
      </div>
    )
  }
  if (state === 'picked') {
    return (
      <div className="badge rounded-pill bg-blue-gradient">
        <i className="bi bi-box-seam me-2"></i>
        Picked
      </div>
    )
  }
  if (state === 'finished') {
    return (
      <div className="badge rounded-pill bg-green-gradient">
        <i className="bi bi-check-all me-2"></i>
        Completed
      </div>
    )
  }
  if (state === 'started') {
    return (
      <div className="badge rounded-pill bg-yellow-gradient">
        <i className="bi bi-arrow-repeat me-2"></i>
        Start
      </div>
    )
  }
  if (state === 'error') {
    return (
      <div className="badge rounded-pill bg-orange-gradient">
        <i className="bi bi-x-lg me-2"></i>
        Failed
      </div>
    )
  }
  if (state.startsWith('render')) {
    return (
      <div className="badge rounded-pill bg-yellow-gradient">
        <i className="bi bi-arrow-repeat me-2"></i>
        Rendering {`(${progress}%)`}
      </div>
    )
  }
  return (
    <div className="badge rounded-pill bg-blue-gradient">
      <i className="bi bi-app me-2"></i>
      {state}
    </div>
  )
}

function displayDuration(d) {
  const pieces = []
  if (d.asHours() > 1) {
    pieces.push(padStart(Math.floor(d.asHours()), 2, '0'))
  }
  pieces.push(padStart(d.minutes(), 2, '0'))
  pieces.push(padStart(d.seconds(), 2, '0'))
  return pieces.join(':')
}

function VideoRow({ video, onPlay, onRetry, onOpenAssets, onRetryDelivery }) {
  let timeTook = null
  if (video.job_started_at && video.job_finished_at) {
    timeTook = displayDuration(
      dayjs.duration(dayjs(video.job_finished_at).diff(video.job_started_at))
    )
  }
  let totalTime = null
  if (video.job_finished_at) {
    totalTime = displayDuration(
      dayjs.duration(dayjs(video.job_finished_at).diff(video.created_at))
    )
  }
  const { user } = useAuthUser()
  const config = useConfig()

  return (
    <tr>
      {user.is_superuser && (
        <td>
          <div>{video.id}</div>
          <div
            className="text-av-primary"
            style={{ fontSize: '60%', fontWeight: 'bold' }}
          >
            {video.job_data.priority}
          </div>
        </td>
      )}
      <td>
        {video.label}
      </td>
      <td>
        {config.video_types[video.video_type] ?? video.video_type}
      </td>
      {user.is_superuser && (
        <td style={{maxWidth:200}}>
          <DisplayJsonData data={video.video_data} />
        </td>
      )}
      <td>{video.location}</td>
      <td>{video.video_data.subtitles ?? true ? 'Yes' : 'No'}</td>
      <td>
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {dayjs(video.created_at).format('DD/MM/YYYY\nHH:mm:ss')}
        </div>
      </td>
      {user.is_superuser && (
        <td>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {dayjs(video.job_updated_at).format('DD/MM/YYYY\nHH:mm:ss')}
          </div>
        </td>
      )}
      {user.is_superuser && (
        <td>
          {video.job_finished_at && (
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {dayjs(video.job_finished_at).format('DD/MM/YYYY\nHH:mm:ss')}
            </div>
          )}
        </td>
      )}
      <td>{timeTook}</td>
      <td>{totalTime}</td>
      <td>
        <VideoState
          cronStatus={video.cron_job_status}
          state={video.job_data?.state}
          progress={video.job_data?.renderProgress}
        />
        <div className="d-flex align-items-center">
          {video.job_reset_count > 0 && (
            <div
              className="text-av-primary mt-2 me-3"
              title={`This video was resetted ${video.job_reset_count} times`}
            >
              <i className="bi bi-power me-1" />
              {video.job_reset_count}
            </div>
          )}
          {video.can_retry_video && (
            <div>
              <button
                onClick={() => onRetry(video)}
                className="p-0 m-0 mt-2 border-0 bg-transparent text-av-primary"
              >
                Retry?
              </button>
            </div>
          )}
        </div>
      </td>
      <td>
        <div className="">
          {video.delivery_configurations?.map((dc) => (<div className="mb-1" key={dc.id}>
            <i>{dc.delivery_channel.delivery_type}</i>: {dc.delivery_status}
            {dc.delivery_channel.delivery_type === 'youtube' && dc.delivery_status === 'delivered' && (<span> <a target="_blank" href={`https://youtube.com/watch?v=${dc.delivery_data.id}`}><i className="bi bi-play-circle" /></a></span>) }
            {dc.delivery_status === 'failed'  && (
              <button
                onClick={() => onRetryDelivery(dc)}
                className="p-0 m-0 border-0 bg-transparent text-av-primary ms-2"
              >
                Retry?
              </button>
            )}
          </div>))}
        </div>
      </td>
      {user.is_superuser && <td>{video.executor_ip}</td>}
      <td>
        <div className="d-flex align-items-center">
          {video.output && (
            <>
              <button
                title="Play"
                className="av-btn-icon-circle me-2"
                onClick={() => onPlay(video)}
              >
                <i className="bi bi-play" />
              </button>
              <a
                title="Download"
                className="av-btn-icon-circle"
                href={video.output}
              >
                <i className="bi bi-download" />
              </a>
            </>
          )}
          {video.youtube_id && (
            <a
              title="YouTube"
              className="av-btn-icon-circle ms-2"
              href={`https://www.youtube.com/watch?v=${video.youtube_id}`}
            >
              <i
                style={{ fontSize: '80%', height: 18 }}
                className="bi bi-box-arrow-up-right"
              />
            </a>
          )}
        </div>
      </td>
      <td>
        <button
          title="Assets"
          onClick={() => onOpenAssets(video)}
          className="av-btn-icon-circle"
        >
          <i className="bi bi-folder" />
        </button>
      </td>
    </tr>
  )
}

export default memo(VideoRow)
