import cronParser from 'cron-parser'

export function getNextCronDate(expression) {
  try {
    const parsed = cronParser.parseExpression(expression)
    return parsed.next().toDate()
  } catch {
    return null
  }
}

export function normalizeServerOrdering(ordering) {
  if (!ordering) {
    return [null, null]
  }
  let direction
  let field
  if (ordering[0] === '-') {
    direction = 'desc'
    field = ordering.substring(1)
  } else {
    direction = 'asc'
    field = ordering
  }
  return [field, direction]
}
