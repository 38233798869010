export default function Informations() {
  return (
    <div className="container av-content my-4 p-4">
      <div className="py-3">
        <h1>KIUU-autovideo-app</h1>
        <div className="fw900">
          Informations, privacy policy, terms of use and contacts for the
          KIUU-autovideo-app functions on Youtube, TikTok and Instagram.
        </div>
      </div>
      <div className="pt-4">
        <h3>Introduction</h3>
        <div>
          This Privacy Policy outlines the practices of the KIUU-autovideo-app
          ("Module", "we", "us", or "our") regarding the collection, use, and
          disclosure of information when the Module automatically publishes
          videos produced by KIUU on clients' owned Instagram, TikTok, or
          YouTube channels. Our primary goal is to respect your privacy and
          comply with applicable data protection laws.
        </div>
      </div>
      <div className="pt-4">
        <h3>What is KIUU</h3>
        <div>
          KIUU is one of{' '}
          <a
            href="https://thevisualagency.com/"
            target="_blank"
            rel="noopener noreferrer"
            link="#C0C0C0"
            style={{ color: 'var(--primary-color)', textDecoration: 'none' }}
          >
            The Visual Agency
          </a>
          's proprietary technologies. It is a platform designed for the
          automated production of animated (motion graphic) videos based on
          external databases and textual information. Using automation logic,
          Kiuu enables the production of large quantities of high-quality and
          highly personalized videos without any human intervention. This
          technology is particularly useful for populating communication
          channels with content frequently, making it an effective tool for
          marketing, communications, and sales efforts. KIUU's main advantage
          lies in its ability to produce and distribute videos automatically,
          offering a scalable solution for creating engaging and informative
          content.
        </div>
      </div>
      <div className="pt-4">
        <h3>Information Collection and Use</h3>
        <div>
          For the KIUU-autovideo-app to function effectively, it requires access
          to your social media accounts via OAuth authentication tokens. Here's
          how we handle this information:
        </div>
        <ul className="pt-2">
          <li>
            <b>OAuth Tokens:</b> When you authorize the Module to connect with
            your Instagram, TikTok, and YouTube accounts, we receive OAuth
            tokens from these platforms. These tokens allow us to publish videos
            on your behalf without accessing or storing your login credentials.
            The OAuth tokens are the only piece of client data we store.
          </li>
          <li>
            <b>How We Use Your Information:</b> The stored OAuth tokens are used
            exclusively for the following purposes:
            <ul>
              <li>
                <b>Automatically Publishing Videos: </b> To publish videos
                produced by KIUU on your Instagram, TikTok, and YouTube channels
                as authorized by you.
              </li>
              <li>
                <b>Service Improvement: </b>To analyze and improve the
                functionality of our Module.
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="pt-4">
        <h3>Data Storage and Security</h3>
        <ul className="pt-2">
          <li>
            <b>Data Storage:</b> The OAuth tokens are securely stored and
            encrypted. Access is limited to authorized Module personnel only.
          </li>
          <li>
            <b>Data Security:</b> We implement industry-standard security
            measures to protect your information from unauthorized access,
            alteration, disclosure, or destruction.
          </li>
        </ul>
      </div>
      <div className="pt-4">
        <h3>Data Sharing and Disclosure</h3>
        <div>
          We do not share or disclose any information to third parties, except
          as required by law or to comply with legal processes.
        </div>
      </div>
      <div className="pt-4">
        <h3>Data Retention</h3>
        <div>
          OAuth tokens are retained only as long as necessary to provide the
          authorized services. You can revoke our access to your accounts at any
          time, resulting in the deletion of the stored tokens from our systems.
        </div>
      </div>
      <div className="pt-4">
        <h3>Your Rights</h3>
        <div>
          You have the right to:
          <ul className="pt-2">
            <li>Access the information we hold about you.</li>
            <li>Correct any incorrect information. </li>
            <li>Delete your information from our systems. </li>
            <li>
              Revoke Consent at any time by disconnecting our access to your
              social media accounts.
            </li>
          </ul>
          Changes to This Privacy Policy:
          <ul className="pt-2">
            <li>
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on our
              website. You are advised to review this Privacy Policy
              periodically for any changes.
            </li>
          </ul>
        </div>
      </div>

      <div className="pt-4">
        <h3>Contact Us</h3>
        <div>
          If you have any questions about this Privacy Policy, please contact us
          at{' '}
          <a
            className="text-av-primary"
            href="mailto:info@thevisualagency.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'var(--primary-color)', textDecoration: 'none' }}
          >
            info@thevisualagency.com
          </a>
        </div>
        <div className="py-3">
          By using the KIUU Module to publish videos on your social media
          channels, you consent to the collection, use, and storage of your
          information as described in this Privacy Policy.
        </div>
      </div>
    </div>
  )
}