import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
// import * as Sentry from '@sentry/react'
import { Outlet, Navigate } from 'react-router'
import Auth from './Auth'
import Login from './pages/Login'
import Home from './pages/Home'
import Video from './pages/Video'
import PageNotFound from './components/PageNotFound'
import { RequireAuth, RequireGuest } from './routing'
import Layout from './components/Layout'
// import Profile from './pages/Profile'
import Schedules from './pages/Schedules'
// import Delivery from './pages/Delivery'
import Machines from './pages/Machines'
import Config from './components/Config'
import Informations from './pages/Informations'
import DeliveryChannels from './pages/DeliveryChannels'
import DeliveryChannelEndAuth from './pages/DeliveryChannelEndAuth'

function checkIsAdmin(user) {
  if (!user.is_superuser) {
    return '/'
  }
}

function checkHasDeliveryManagement(user) {
  if (!user.delivery_management) {
    return '/'
  }
}

export default function App() {
  return (
    <Auth>
      <Config>
        <Router>
          <Routes>
            {/* GUEST */}
            <Route
              path="/"
              element={
                <RequireGuest redirectToReferrer={false} redirectTo="/video">
                  <Outlet />
                </RequireGuest>
              }
            >
              <Route index element={<Home />} />
              <Route path="login" element={<Login />} />
            </Route>
            {/* AUTH */}

            <Route
              path="/"
              element={
                <RequireAuth>
                  <Layout />
                </RequireAuth>
              }
            >
              <Route index element={<Navigate to="/video" />} />
              <Route path="video" element={<Video />} />
              <Route path="schedules" element={<Schedules />} />
              {/* <Route path="profile" element={<Profile />} /> */}
            </Route>
            {/* ADMIN */}
            <Route
              path="/"
              element={
                <RequireAuth redirectTest={checkIsAdmin}>
                  <Layout />
                </RequireAuth>
              }
            >
              <Route path="machines" element={<Machines />} />
            </Route>
            {/* DELIVERY MANAGEMENT */}
            <Route
              path="/"
              element={
                <RequireAuth redirectTest={checkHasDeliveryManagement}>
                  <Layout />
                </RequireAuth>
              }
            >
              <Route path="delivery-channels" element={<DeliveryChannels />} />
              {/* <Route path="delivery" element={<Delivery />} /> */}
            </Route>

            <Route
              path="/delivery-channel-end-auth"
              element={
                <RequireAuth redirectTest={checkHasDeliveryManagement}>
                  <DeliveryChannelEndAuth />
                </RequireAuth>
              }
            ></Route>

            <Route path="info" element={<Informations />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </Config>
    </Auth>
  )
}
