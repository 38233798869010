export default function PageNotFound() {
  return (
    <div className="text-center mt-5 text-white">
      <h1>
        404 <br />
        Page Not Found
      </h1>
    </div>
  )
}
