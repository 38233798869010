import ReconnectingWebSocket from 'reconnecting-websocket'
import { useEffect, useRef } from 'react'
import { rj, useRunRj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import { useAuthUser } from 'use-eazy-auth'
import api from '../api'

const DeliveryChannelsListState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: 100,
    })
  )
  .actions(() => ({
    updateDelivery: (deliveryChannel) => ({
      type: 'UPDATE_DELIVERY_CHANNEL',
      payload: deliveryChannel,
    }),
  }))
  .reducer((oldReducer) => (state, action) => {
    if (action.type === 'UPDATE_DELIVERY_CHANNEL') {
      let updated = false
      const nextState = {
        ...state,
        data: {
          ...state.data,
          list: state.data.list.map((v) => {
            if (v.id === action.payload.id) {
              updated = true
              return {
                ...v,
                ...action.payload
              }
            } else {
              return v
            }
          }),
        },
      }
      if (updated) {
        return nextState
      }
      return state
    }
    return oldReducer(state, action)
  })
  .computed({
    list: 'getList',
    pagination: 'getPagination',
    pending: 'isPending',
    error: 'getError',
  })
  .effect({
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get(`/api/or/dash/delivery-channels`, params),
  })


  const WS_URL =
  process.env.NODE_ENV === 'production'
    ? `wss://${window.location.hostname}`
    : 'ws://localhost:8000'



  export function useDeliveryChannelsList(params) {
    const out = useRunRj(DeliveryChannelsListState, [params], false)
    const [{ list }, { run, updateDelivery }] = out
    const { token } = useAuthUser()

    const lastParamsRef = useRef()
    useEffect(() => {
      lastParamsRef.current = params
    })
  
    const openWs = list !== null
    useEffect(() => {
      if (!openWs) {
        return
      }
      const ws = new ReconnectingWebSocket(`${WS_URL}/ws/events-delivery/`)
      document.cookie = `token=${token};`
      ws.addEventListener('message', (e) => {
        const data = JSON.parse(e.data)
        if (data.created) {
          const lastParams = lastParamsRef.current
          run(lastParams)
        } else {
          updateDelivery(data.delivery_channel)
        }
      })
      return () => ws.close()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openWs])
  
    return out
  }




