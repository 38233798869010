import { useState, memo } from 'react'

function DisplayJsonData({ data }) {
  const [expandKeys, setExpandKeys] = useState({})
  return (
    <div>
      {Object.keys(data).map((k) => (
        <div key={k}>
          <b>{k}</b>
          {': '}
          {typeof data[k] === 'object' && data[k] !== null ? (
            <>
              <button
                onClick={() => setExpandKeys((o) => ({ ...o, [k]: !o[k] }))}
                style={{
                  display: 'inline-block',
                  borderRadius: 10,
                  fontSize: 12,
                  height: 20,
                  width: 20,
                }}
                className="av-btn-icon-circle"
              >
                {expandKeys[k] ? '-' : '+'}
              </button>
              <div style={{ marginLeft: 10 }}>
                {expandKeys[k] && <DisplayJsonData data={data[k]} />}
              </div>
            </>
          ) : (
            <span>{String(data[k])}</span>
          )}
        </div>
      ))}
    </div>
  )
}

export default memo(DisplayJsonData)
