import { createContext, useContext } from 'react'
import { rj, useRunRj } from 'react-rocketjump'
import api from '../api'

const ConfigState = rj().effect({
  effectCaller: 'configured',
  effect: (t) => () => api.auth(t).get('/api/or/dash/config'),
})

export function useFetchConfig() {
  return useRunRj(ConfigState, [])[0].data
}

/**
 * @returns {{
 *  templates: Record<string, string>
 *  video_types: Record<string, string>
 * }}
 */
export function useConfig() {
  return useContext(ConfigContext)
}

export const ConfigContext = createContext({})
