import classNames from 'classnames'
import dayjs from 'dayjs'
import { useCallback, useMemo, useState } from 'react'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './ColDateRange.css'

const EMPTY_LIST = []

export default function ColDateRange({ label, from, to, onChange }) {
  const [isOpen, setIsOpen] = useState(false)

  const value = useMemo(() => {
    return [{
      startDate: from ? dayjs(from).toDate() : null,
      endDate: to ? dayjs(to).toDate() : null,
      key: 'selection',
    }]
  }, [from, to])

  const handleOnChange = useCallback(
    (selection) => {
      onChange({
        from: dayjs(selection.selection.startDate).format('YYYY-MM-DD'),
        to: dayjs(selection.selection.endDate).format('YYYY-MM-DD'),
      })
    },
    [onChange]
  )

  return (
    <div className="d-flex align-items-center ColDateRangeContainer">
      <div className="ColDateRangeContainerLabel">{label}</div>
      <div>
        <button
          title="Filter"
          className="av-btn-icon-circle ms-2 position-relative"
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="bi bi-filter" />
          {(from || to) && <span className="active-multi-filter-circle" />}
        </button>
      </div>
      <div
        className={classNames('ColDateRange', {
          close: !isOpen,
        })}
      >
        <button
          onClick={() => {
            onChange({
              from: undefined,
              to: undefined,
            })
          }}
          title="Reset"
          className="av-btn-icon-circle ms-2 ColDateRangeReset"
        >
          <i className="bi bi-arrow-clockwise" />
        </button>
        <DateRangePicker
          inputRanges={EMPTY_LIST}
          staticRanges={EMPTY_LIST}
          onChange={handleOnChange}
          ranges={value}
          startDatePlaceholder={'Start date'}
          endDatePlaceholder={'End date'}
        />
      </div>
    </div>
  )
}
