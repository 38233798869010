import { ReactComponent as Logo } from '../assets/kiuu_logo.svg'

export default function DeliveryChannels() {

  //get delivery_channel_label from url
  const urlParams = new URLSearchParams(window.location.search)
  const deliveryChannelLabel = urlParams.get('delivery_channel_label')
  const error = urlParams.get('error')
  


  return (
    <div>
      <div className="d-flex justify-content-center p-3 mt-5">
        {' '}
        <Logo />
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center p-3">
        {<p className="text-white text-center">
          {!error && <span>The delivery configuration for <b>{deliveryChannelLabel}</b> has been updated!</span>}
          {error && <span><b>Error:</b> The delivery configuration for <b>{deliveryChannelLabel}</b> was not updated.</span>}
        </p>}
        <p className="text-white text-center">You can <span onClick={() => window.close()}>close</span> this page.</p>
      </div>
    </div>
  )
}
