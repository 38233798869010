import ReconnectingWebSocket from 'reconnecting-websocket'
import api from '../api'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import { rj, useRunRj } from 'react-rocketjump'
import { useAuthUser } from 'use-eazy-auth'
import { useEffect, useRef } from 'react'

const VideoListState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: 100,
    })
  )
  .actions(() => ({
    updateVideo: (video) => ({
      type: 'UPDATE_VIDEO',
      payload: video,
    }),
  }))
  .reducer((oldReducer) => (state, action) => {
    if (action.type === 'UPDATE_VIDEO') {
      let updated = false
      const nextState = {
        ...state,
        data: {
          ...state.data,
          list: state.data.list.map((v) => {
            if (v.id === action.payload.id) {
              updated = true
              return action.payload
            } else {
              return v
            }
          }),
        },
      }
      if (updated) {
        return nextState
      }
      return state
    }
    return oldReducer(state, action)
  })
  .mutations({
    retryVideo: {
      effect: (t) => (id) => api.auth(t).put(`/api/or/dash/video/${id}/retry`),
      updater: 'updateItem',
    },
  })
  .computed({
    list: 'getList',
    pagination: 'getPagination',
    pending: 'isPending',
    error: 'getError',
  })
  .effect({
    name: 'VideoList',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/or/dash/video', params),
  })

const WS_URL =
  process.env.NODE_ENV === 'production'
    ? `wss://${window.location.hostname}`
    : 'ws://localhost:8000'

export function useVideoList(params) {
  const out = useRunRj(VideoListState, [params], false)
  const [{ list }, { run, updateVideo }] = out
  const { token } = useAuthUser()

  const lastParamsRef = useRef()
  useEffect(() => {
    lastParamsRef.current = params
  })

  const openWs = list !== null
  useEffect(() => {
    if (!openWs) {
      return
    }
    const ws = new ReconnectingWebSocket(`${WS_URL}/ws/events/`)
    document.cookie = `token=${token};`
    ws.addEventListener('message', (e) => {
      const data = JSON.parse(e.data)
      if (data.created) {
        const lastParams = lastParamsRef.current
        run(lastParams)
      } else {
        updateVideo(data.video)
      }
    })
    return () => ws.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openWs])

  return out
}

const VideosDomainsState = rj().effect({
  effectCaller: 'configured',
  effect: (t) => () => api.auth(t).get('/api/or/dash/video/domains'),
})

export function useVideoDomains() {
  return useRunRj(VideosDomainsState, [])
}
