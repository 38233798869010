import { ReactComponent as Logo } from '../assets/kiuu_logo.svg'
import { Link } from 'react-router-dom'

export default function Login() {
  
  return (
    <div>
      <div className="d-flex justify-content-end p-3">
        <Link to="/login" className="btn btn-outline-light border-radius-3">
          Log in
        </Link>
      </div>

      <div
        className="row mt-5 p-2"
      >
        <div className="col-md-4 offset-md-4">
          <div className="d-flex justify-content-center p-3">
            {' '}
            <Logo />
          </div>
          <div className="d-flex justify-content-center text-av-primary-light-pure mfs-18 p-1">
            Video Automation Platform
          </div>
          <div className="d-flex justify-content-center mfs-12 mb-5">
            {' '}
            <a
              href="https://thevisualagency.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'white' }}
            >
              by The Visual Agency
            </a>
          </div>

          <div
            className="d-flex justify-content-center p-3 mb-5 text-white mfs-12"
            style={{ height: 312, position: 'relative' }}
          >
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              src="https://player.vimeo.com/video/763702956?badge=0&autopause=0player_id=0&app_id=58479&autoplay=1&muted=1"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              title="Kiuu, motion graphic production software"
            ></iframe>
          </div>

          <div className="text-center mb-5 text-white">
            <p>
              Kiuu is a platform created for the automated production of
              animated (motion graphic) videos based on external databases and
              textual information. Using automation logic Kiuu allows for the
              production of large quantities of high-quality and highly
              personalized videos. 
            </p>
            <p>discover more on thevisualagency.com</p>
          </div>
        </div>
      </div>
      <footer className="w-100 d-flex justify-content-center mfs-12 position-fixed bottom-0 p-3">
        <a
          href="/info"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'white' }}
        >
          Social Apps legal notice and terms of services
        </a>
      </footer>
    </div>
  )
}
