import { useMemo } from 'react'

function paginationRange(current, total, delta = 1, separator = '...') {
  if (total <= 1) return [1]

  const center = [current]
  for (let i = 1; i <= delta; i++) {
    center.push(current + i)
    center.unshift(current - i)
  }
  if (delta === 1 && current === 1) {
    center.push(3)
  }
  if (delta === 1 && current === total) {
    center.unshift(total - 2)
  }

  const filteredCenter = center.filter((p) => p > 1 && p < total),
    includeLeft = current === delta + 2 + 1,
    includeRight = current === total - delta - 2,
    includeLeftDots = current > delta + 2 + 1,
    includeRightDots = current < total - delta - 2

  if (includeLeft) filteredCenter.unshift(2)
  if (includeRight) filteredCenter.push(total - 1)

  if (includeLeftDots) filteredCenter.unshift(separator)
  if (includeRightDots) filteredCenter.push(separator)

  return [1, ...filteredCenter, total]
}

const Paginator = ({
  numPages,
  currentPage,
  goToPage,
  className = 'd-flex justify-content-center align-items-center',
}) => {
  const longPages = useMemo(
    () => paginationRange(currentPage, numPages, 10),
    [currentPage, numPages]
  )

  return (
    <nav aria-label="Paginator" className={className}>
      <ul className="pagination my-2">
        <li
          className={`page-item pointer ${
            currentPage === null || currentPage === 1 ? 'disabled' : ''
          }`}
        >
          <span
            className="page-link"
            aria-label="Previous"
            onClick={() => goToPage(currentPage - 1)}
          >
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Indietro</span>
          </span>
        </li>
        {longPages.map((page, i) =>
          typeof page === 'string' ? (
            <li className="page-item" key={i}>
              <span className="page-link">{page}</span>
            </li>
          ) : (
            <li
              key={i}
              style={{ cursor: 'pointer' }}
              className={`page-item ${currentPage === page ? 'active' : ''} `}
            >
              <span onClick={() => goToPage(page)} className="page-link">
                {page}
              </span>
            </li>
          )
        )}
        <li
          className={`page-item pointer ${
            currentPage === null || !(currentPage < numPages) ? 'disabled' : ''
          }`}
        >
          <span
            className="page-link"
            aria-label="Next"
            onClick={() => goToPage(currentPage + 1)}
          >
            <span className="sr-only">Avanti</span>
            <span aria-hidden="true">&raquo;</span>
          </span>
        </li>
      </ul>
    </nav>
  )
}

export default Paginator
