import dayjs from 'dayjs'
import { memo } from 'react'

function MachineRow({ machine }) {
  return (
    <tr>
      <td>{machine.ip_address}</td>
      <td>{machine.description}</td>
      <td>
        {machine.score}
        {' / '}
        {machine.reboot_score}
      </td>
      <td>{dayjs(machine.updated_at).format('DD/MM/YYYY HH:mm')}</td>
      <td>
        {machine.pickup_enabled ? (
          <i className="bi bi-check-circle-fill text-success"></i>
        ) : (
          <i className="bi bi-x-circle-fill text-danger"></i>
        )}
      </td>
    </tr>
  )
}

export default memo(MachineRow)
