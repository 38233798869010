import { startTransition, useMemo, useState } from 'react'
import VideoRow from '../components/VideoRow'
import { useVideoDomains, useVideoList } from '../hooks/video'
import { useDeliveryRetry } from '../hooks/videodelivery'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import Paginator from '../components/Paginator'
import DisplayJsonData from '../components/DisplayJsonData'
import { useAuthUser } from 'use-eazy-auth'
import ColMultiSelector from '../components/ColMuliSelector/ColMuliSelector'
import { normalizeServerOrdering } from '../utils'
import { ColAlphaSort } from '../components/ColAlphaSort'
import ColSort from '../components/ColSort'
import { useConfig } from '../hooks/config'
import ColDateRange from '../components/ColDateRange'

export default function Video() {
  const config = useConfig()
  const [{ data:deliveryRetryOutput }, { run:performDeliveryRetry, clean: cleanDeliveryRetry}] = useDeliveryRetry()
  const [{ data: videoDomains }] = useVideoDomains()
  const [UIParams, setUIParams] = useState({
    page: 1,
    video_type: undefined,
    label: undefined,
    ordering: '',
    from_date: undefined,
    to_date: undefined,
  })
  const [sortField, sortDirection] = normalizeServerOrdering(UIParams.ordering)

  const videoTypesOptions = useMemo(() => {
    return (videoDomains?.video_types ?? []).map((value) => ({
      value,
      label: config.video_types[value] ?? value,
    }))
  }, [config, videoDomains])

  const labelTypesOptions = useMemo(() => {
    return (videoDomains?.video_labels ?? []).map((value) => ({
      value,
      label: value,
    }))
  }, [config, videoDomains])

  function handleSortChange(field) {
    let ordering
    // New sort
    if (field !== sortField) {
      ordering = `-${field}`
    }
    // Other direction
    else if (sortDirection === 'desc') {
      ordering = field
    } else {
      // No sort
      ordering = ''
    }
    setUIParams((prevParams) => ({
      ...prevParams,
      ordering,
      page: 1,
    }))
    startTransition(() => {
      setApiParams((prevParams) => ({
        ...prevParams,
        ordering,
        page: 1,
      }))
    })
  }

  const [apiParams, setApiParams] = useState(UIParams)
  const [{ list, pagination }, { retryVideo }] = useVideoList(apiParams)

  const [retrying, setRetrying] = useState(false)
  const [retryError, setRetryError] = useState(null)

  const [modalPlayVideo, modalPlayVideoActions] = useModalTrigger()
  const [modalRetryVideo, modalRetryVideoActions] = useModalTrigger()
  const [modalAssetes, modalAssetsActions] = useModalTrigger()
  const [modalDelivery, modalDeliveryActions] = useModalTrigger()
  const [retryDeliveryError, setRetryDeliveryError] = useState(null)
  const [retryingDelivery, setRetryingDelivery] = useState(false)

  const { user } = useAuthUser()

  return (
    <div className="container-fluid av-content">
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              {user.is_superuser && <th>#</th>}
              <th className="col-more-zindex">
                <ColMultiSelector
                  isAllSelected={
                    // No user filter so all options selected
                    UIParams.label === undefined ||
                    // User select all options so all selected
                    UIParams.label.length === labelTypesOptions.length
                  }
                  allLabel={'All Labels'}
                  label={'Label'}
                  value={UIParams.label ?? []}
                  onChange={(label) => {
                    setUIParams((prevParams) => ({
                      ...prevParams,
                      label,
                      page: 1,
                    }))
                    startTransition(() => {
                      setApiParams((prevParams) => ({
                        ...prevParams,
                        label,
                        page: 1,
                      }))
                    })
                  }}
                  options={labelTypesOptions}
                />
              </th>
              <th className="col-more-zindex">
                <ColMultiSelector
                  isAllSelected={
                    // No user filter so all options selected
                    UIParams.video_type === undefined ||
                    // User select all options so all selected
                    UIParams.video_type.length === videoTypesOptions.length
                  }
                  allLabel={'All Templates'}
                  label={'Template'}
                  value={UIParams.video_type ?? []}
                  onChange={(video_type) => {
                    setUIParams((prevParams) => ({
                      ...prevParams,
                      video_type,
                      page: 1,
                    }))
                    startTransition(() => {
                      setApiParams((prevParams) => ({
                        ...prevParams,
                        video_type,
                        page: 1,
                      }))
                    })
                  }}
                  options={videoTypesOptions}
                />
              </th>
              {user.is_superuser && <th style={{ maxWidth: 200 }}>Data</th>}
              <th>
                <ColAlphaSort
                  label={'Location'}
                  direction={sortDirection}
                  active={sortField === 'location'}
                  onClick={() => handleSortChange('location')}
                />
              </th>
              <th>Subtitle</th>
              <th style={{ zIndex: 4 }}>
                <div className="d-flex">
                  <ColDateRange
                    onChange={({ from, to }) => {
                      setUIParams((prevParams) => ({
                        ...prevParams,
                        from_date: from,
                        to_date: to,
                        page: 1,
                      }))
                      startTransition(() => {
                        setApiParams((prevParams) => ({
                          ...prevParams,
                          from_date: from,
                          to_date: to,
                          page: 1,
                        }))
                      })
                    }}
                    from={UIParams.from_date}
                    to={UIParams.to_date}
                    label={'Created date'}
                  />
                </div>
              </th>
              {user.is_superuser && <th>Last update</th>}
              {user.is_superuser && <th>Publishing date</th>}
              <th>
                <ColSort
                  label={'Rendering time'}
                  direction={sortDirection}
                  active={sortField === 'rendering_time'}
                  onClick={() => handleSortChange('rendering_time')}
                />
              </th>
              <th>
                <ColSort
                  label={'Total processing time'}
                  direction={sortDirection}
                  active={sortField === 'total_time'}
                  onClick={() => handleSortChange('total_time')}
                />
              </th>
              <th>Status</th>
              <th>Delivery</th>
              {user.is_superuser && <th>Worker IP</th>}
              <th>Final video</th>
              <th>Assets</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((video) => (
                <VideoRow
                  onPlay={modalPlayVideoActions.open}
                  onRetry={modalRetryVideoActions.open}
                  onOpenAssets={modalAssetsActions.open}
                  onRetryDelivery={modalDeliveryActions.open}
                  video={video}
                  key={video.id}
                />
              ))}
          </tbody>
        </table>
      </div>
      <Paginator
        numPages={pagination.numPages}
        currentPage={UIParams.page}
        goToPage={(page) => {
          setUIParams((prevParams) => ({
            ...prevParams,
            page,
          }))
          startTransition(() => {
            setApiParams((prevParams) => ({
              ...prevParams,
              page,
            }))
          })
        }}
      />
      <Modal
        size="xl"
        isOpen={modalPlayVideo.isOpen}
        toggle={modalPlayVideoActions.toggle}
        onClosed={modalPlayVideoActions.onClosed}
      >
        <ModalHeader toggle={modalPlayVideoActions.toggle}>Video</ModalHeader>
        <ModalBody>
          {modalPlayVideo.value && (
            <video
              controls
              className="preview-video"
              src={modalPlayVideo.value.output}
            />
          )}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalAssetes.isOpen}
        toggle={modalAssetsActions.toggle}
        onClosed={modalAssetsActions.onClosed}
      >
        <ModalHeader toggle={modalAssetsActions.toggle}>Assets</ModalHeader>
        <ModalBody>
          {modalAssetes.value && (
            <div className="table-responsive">
              <table className="table table-striped">
                <tbody>
                  {modalAssetes.value.assets.map((asset) => (
                    <tr key={asset.key}>
                      <td>{asset.key}</td>
                      <td>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="av-btn-icon-circle"
                          href={asset.asset}
                        >
                          <i
                            style={{ fontSize: '80%', height: 18 }}
                            className="bi bi-box-arrow-up-right"
                          ></i>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalRetryVideo.isOpen}
        toggle={modalRetryVideoActions.toggle}
        onClosed={() => {
          modalRetryVideoActions.onClosed()
          setRetryError(null)
        }}
      >
        <ModalHeader toggle={modalRetryVideoActions.toggle}>
          Retry Video?
        </ModalHeader>
        <ModalBody>
          {modalRetryVideo.value && (
            <div>
              Are you sure to retry video #{modalRetryVideo.value.id}{' '}
              {modalRetryVideo.value.video_type} ?
              <br />
              <br />
              <DisplayJsonData data={modalRetryVideo.value.video_data} />
            </div>
          )}
          {retryError && (
            <Alert className="mt-4" color="danger">
              Failed to retry video.
              <br />
              {String(retryError?.response?.detail ?? '')}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button
            onClick={() => {
              modalRetryVideoActions.close()
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={retrying}
            onClick={() => {
              setRetrying(true)
              setRetryError(null)
              retryVideo
                .onFailure((error) => {
                  setRetryError(error)
                  setRetrying(false)
                })
                .onSuccess(() => {
                  modalRetryVideoActions.close()
                  setRetrying(false)
                })
                .run(modalRetryVideo.value.id)
            }}
            color="success"
          >
            Retry!
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalDelivery.isOpen}
        toggle={modalDeliveryActions.toggle}
        onClosed={() => {
          modalDeliveryActions.onClosed()
          setRetryDeliveryError(null)
        }}
      >
        <ModalHeader toggle={modalDeliveryActions.toggle}>
          Retry delivery
        </ModalHeader>
        <ModalBody>
          {modalDelivery.value && (
            <div>
              Are you sure to retry delivery {modalDelivery.value.delivery_channel.label}{' '}
            </div>
          )}
          {retryDeliveryError && (
            <Alert className="mt-4" color="danger">
              Failed to retry delivery of video.
              <br />
              {String(retryDeliveryError?.response?.detail ?? '')}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button
            onClick={() => {
              modalDeliveryActions.close()
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={retryingDelivery}
            onClick={() => {
              
              performDeliveryRetry
                .onFailure((error) => {
                  setRetryDeliveryError(error)
                  setRetryingDelivery(false)
                })
                .onSuccess(() => {
                  modalDeliveryActions.close()
                  setRetryingDelivery(false)
                  setRetryDeliveryError(null)
                })
                .run(modalDelivery.value.id)
            }}
            color="success"
          >
            Retry!
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
