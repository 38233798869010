import { useEffect, useState } from 'react'
import { useAuthActions, useAuthState } from 'use-eazy-auth'
import { ReactComponent as Logo } from '../assets/kiuu_logo.svg'
import { Link } from 'react-router-dom'

export default function Login() {
  const { loginLoading, loginError } = useAuthState()
  const { login, clearLoginError } = useAuthActions()

  // Clear login error when Login component unmount
  useEffect(() => () => clearLoginError(), [clearLoginError])

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  return (
    <form
      className="row mt-5 p-2"
      onSubmit={(e) => {
        e.preventDefault()
        if (email !== '' && password !== '') {
          login({ email, password })
        }
      }}
    >
      <div className="col-md-4 offset-md-4">
        <div className="d-flex justify-content-center p-3">
          <Link to="/">
          {' '}
          <Logo />
          </Link>
        </div>
        
        
        <div className="d-flex justify-content-center p-3 text-white">
          <h5>Login</h5>
        </div>

        <div className="card">
          <div className="card-body px-4">
            <div className="mb-3">
              <label className="text-av-primary">Email</label>
              <input
                name="email"
                className="form-control"
                type="email"
                value={email}
                onChange={(e) => {
                  clearLoginError()
                  setEmail(e.target.value)
                }}
              />
            </div>
            <div className="mb-3">
              <label className="text-av-primary">Password</label>
              <input
                name="password"
                className="form-control"
                type="password"
                value={password}
                onChange={(e) => {
                  clearLoginError()
                  setPassword(e.target.value)
                }}
              />
            </div>
            <div className="d-flex justify-content-end">
              <button
                className="btn av-btn w-100 login-btn"
                disabled={loginLoading}
              >
                <span className="log-in-txt-s">Log in</span>
                <span className="log-in-txt-e">
                  <i className="ms-2 bi bi-arrow-right" />
                </span>
              </button>
            </div>
            {loginError && (
              <div className="alert alert-danger mt-3">Wrong credentials</div>
            )}
          </div>
        </div>
      </div>
      <footer className="d-flex justify-content-center mfs-12 position-fixed bottom-0 p-3">
        <a
          href="/info"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'white' }}
        >
          Social Apps legal notice and terms of services
        </a>
      </footer>
    </form>
  )
}
