import ColSort from './ColSort'

export function ColAlphaSort({ label, onClick, active, direction }) {
  return (
    <ColSort
      label={label}
      onClick={onClick}
      active={active}
      direction={direction}
      classOff="av-icon-alpha-sort"
      classDesc="bi-sort-alpha-down"
      classAsc="bi-sort-alpha-up"
    />
  )
}
