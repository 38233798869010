import dayjs from 'dayjs'
import { memo, useMemo } from 'react'


import duration from 'dayjs/plugin/duration'
import classNames from 'classnames'

dayjs.extend(duration)

function DeliveryTimeline({ deliveryChannel }) {

  console.log(deliveryChannel)

  const expiryDate = dayjs(deliveryChannel.expiry)
  const tokenDate = dayjs(deliveryChannel.tokens_update_date)
  const today = dayjs()

  
  const duration = dayjs.duration(expiryDate.diff(tokenDate))
  const durationNow = dayjs.duration(expiryDate.diff(today))

  
  const totalSeconds = duration.asSeconds()
  let remainingSeconds = durationNow.asSeconds()
 
  const width = remainingSeconds > 0 ? (remainingSeconds / totalSeconds) * 100 : 0


  const colorClassName = useMemo(() => {
    if (width > 66) {
      return 'bg-green-gradient'
    } else if (width > 33) {
      return 'bg-yellow-gradient'
    } else {
      return 'bg-orange-gradient'
    }
    
  }, [width])

  const durationAsString = useMemo(() => {
    const duration = dayjs.duration(remainingSeconds, 'seconds')
    
    if(remainingSeconds > 0){
      const durString = parseInt(duration.asDays(), 10)
      return `-${durString} D`
    } else {
      const durString = parseInt(-duration.asDays(), 10)
      return `Expired (${durString} D)`
    }
  }, [remainingSeconds])

  return (
    <div className={classNames('delivery-timeline')}>
      <div
        className={classNames('delivery-timeline-remaining', colorClassName)}
        style={{ width: `${width}%` }}
      >
      </div>
      <div className='delivery-timeline-remaining-value'>
        {durationAsString}
      </div>
    </div>
  )
}


function DeliveryChannelRow({ deliveryChannel }) {
  const finalUrl =
    window.location.origin +
    '/delivery-channel-end-auth?delivery_channel_label=' +
    deliveryChannel.label
  const url = `${deliveryChannel.auth_token_url}&final_redirect_uri=${finalUrl}`
  const tokens = deliveryChannel.tokens ?? {}
  const hasTokens = Object.keys(tokens).length > 0
  const actionText = hasTokens ? 'Renew authorization' : 'Authorize'

  return (
    <tr>
      <td className="align-middle">{deliveryChannel.label}</td>
      <td className="align-middle">{deliveryChannel.delivery_type}</td>
      <td className="align-middle">
        {deliveryChannel.delivery_type !== 'ftp' &&
          dayjs(deliveryChannel.tokens_update_date).format('DD/MM/YYYY HH:mm')}
      </td>
      <td className="align-middle">
        {deliveryChannel.expiry && dayjs(deliveryChannel.expiry).format('DD/MM/YYYY HH:mm')}
      </td>
      <td className="align-middle">
        {deliveryChannel.expiry && <DeliveryTimeline deliveryChannel={deliveryChannel} />}
      </td>
      <td className="align-middle">
        {deliveryChannel.auth_token_url && (
          <a className="btn av-btn-secondary-flat" target="_blank" href={url}>
            {actionText}
          </a>
        )}
      </td>
    </tr>
  )
}

export default memo(DeliveryChannelRow)
