import { useEffect, useState } from 'react'
import { rj, useRunRj } from 'react-rocketjump'
import api from '../api'
import { getNextCronDate } from '../utils'

export function useNextCronDate(expression) {
  const [nextDate, setNextDate] = useState(() => getNextCronDate(expression))

  useEffect(() => {
    let id

    function handler() {
      const nextDate = getNextCronDate(expression)
      setNextDate((prevDate) => {
        if (String(prevDate) === String(nextDate)) {
          return prevDate
        }
        return nextDate
      })
      schedule()
    }

    function schedule() {
      let sleepTime = 60 - new Date().getSeconds()
      if (sleepTime === 0) {
        sleepTime = 60
      }
      sleepTime = sleepTime * 1000
      id = setTimeout(handler, sleepTime)
    }

    handler()

    return () => {
      clearTimeout(id)
    }
  }, [expression])

  return nextDate
}

const CronJobsListSte = rj()
  .mutations({
    patchJob: {
      effect: (t) => (partialJob) =>
        api
          .auth(t)
          .patch(`/api/or/dash/cron-jobs/${partialJob.id}`, partialJob),
      updater: (s, job) => ({
        ...s,
        data: s.data.map((j) => (job.id === j.id ? job : j)),
      }),
    },
  })
  .effect({
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get(`/api/or/dash/cron-jobs`, params),
  })

export function useCronJobsList(params) {
  return useRunRj(CronJobsListSte, [params], false)
}
