import MachineRow from '../components/MachineRow'
import { useMachinesList } from '../hooks/machines'

export default function Machines() {
  const [{ data: machines }] = useMachinesList()

  return (
    <div className="container-fluid av-content">
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>IP</th>
              <th>Description</th>
              <th>Score</th>
              <th>Last Update</th>
              <th>Pickup Jobs?</th>
            </tr>
          </thead>
          <tbody>
            {machines &&
              machines.map((machine) => (
                <MachineRow machine={machine} key={machine.ip_address} />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
